export const custom = {
  /**
  * Idiomas disponibles ['es','en','pt','cat']
  */
  availableLangs: ['es'],

  /**
   * Idioma por defecto: ['es']
   */
  defaultLang: 'es',

  /**
   * Versión de la API: v2|v3
   */
  apiVersion: 'v3',

  /**
   * API key de conexión a la api definida en cada entorno
   * (debe ser el mismo en todos los servicios)
   */
  apiKey: '79cb61d3-a9ef-403e-8b05-289db7758bda',

  /**
   * URL de la app
   *
   * subdominio.dominio
   */
  appUrlDomain: 'chubbvenca.alares',

  /**
  * Nombre de la app
  */
  appName: 'Chubb',

  /**
  * Código de analytics 'G-XXXXXXXXXXX'
  * (Activado en pro, desactivado en pre y local)
  */
  analyticsId: 'G-SPVYDZJRDN',

  /** true o false. Define si es necesario hacer login para acceder a los contenidos */
  protected: false,

  /** Registro habilitado o deshabilitado */
  registration: false,
  videoCallsUrl: 'https://videollamada.vivofacil.com/?kid=AljJRe39SLEsWhMFg2pqTrA',
  mailTransport: 'alares',
  brandName: 'Alares',
  appUrlTld: 'es',
  /** true o false. Define si los formularios son para conciliación o no */
  conciliation: false
};
